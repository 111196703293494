<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="viewModel.model.status | convertIdToText('blog_status')"
                :badgeStyle="viewModel.model.status | convertIdToColor('blog_status')"
                :badgeFill="false" />
            </span>
          </td>
          <th>조회수</th>
          <td>
            <span class="txt_tbl">
              {{ viewModel.model.view_count || '-' }}
            </span>
          </td>
        </tr>
        <tr>
          <th>포스트 타입</th>
          <td>
            <span class="txt_tbl">
              <Badge
                :text="viewModel.model.post_type | convertIdToText('post_type')"
                :badgeStyle="viewModel.model.post_type | convertIdToColor('post_type')"
                :badgeFill="false" />
            </span>
          </td>
          <th>댓글유무</th>
          <td>
            <span class="txt_tbl">
              {{ viewModel.model.use_comments | convertIdToText('blog_comment') }}
            </span>
          </td>
        </tr>
        <tr>
          <th>등록일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.created_at | convertDateFormat() }}</span>
          </td>
          <th>최종수정일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.updated_at | convertDateFormat() }}</span>
          </td>
        </tr>
        <tr>
          <th>이미지</th>
          <td colspan="3">
            <img
              v-if="viewModel.model.thumbnail.length > 0"
              :src="viewModel.model.thumbnail[0].src"
              class="img_g">
            <span v-else class="txt_tbl">-</span>
          </td>
        </tr>
        <tr>
          <th>SNS 공유 이미지</th>
          <td colspan="3">
            <img
              v-if="viewModel.model.share_thumbnail.length > 0"
              :src="viewModel.model.share_thumbnail[0].src"
              class="img_g">
            <span v-else class="txt_tbl">-</span>
          </td>
        </tr>
        <tr>
          <th>비디오</th>
          <td colspan="3">
            <div v-if="viewModel.model.video_url.length > 0" style="width:100%">
              <div style="overflow:hidden;position:relative;z-index:2;background-color:#232323">
                <video
                  width="100%"
                  height="100%"
                  loop
                  controls
                  playsInline
                  preload="none"
                  :poster="viewModel.model.video_thumbnail.length > 0 ? viewModel.model.video_thumbnail[0].src : ''"
                >
                  <source :src="viewModel.model.video_url[0].src" type="video/mp4" />
                </video>
              </div>
              <p v-if="viewModel.model.video_thumbnail.length === 0" class="desc_info">💡 비디오 포스터 이미지가 미등록되어 재생전 화면이 검은색입니다</p>
            </div>
            <div v-else-if="viewModel.model.video_thumbnail.length > 0">
              <img
                :src="viewModel.model.video_thumbnail[0].src"
                class="img_g">
              <p class="desc_info">💡 비디오 포스터 이미지만 등록하셨습니다 비디오를 등록해주세요</p>
            </div>
            <span v-else class="txt_tbl">X</span>
          </td>
        </tr>
        <tr>
          <th>링크명</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.link }}</span>
          </td>
        </tr>
        <tr>
          <th>제목</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.title }}</span>
          </td>
        </tr>
        <tr>
          <th>내용</th>
          <td colspan="3">
            <div class="editor_view" v-html="viewModel.model.contents"></div>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="CONTENT_TEENS_ECONOMIC_NEWS_LIST"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="fourthly"
          text="삭제"
          @onClickBtn="viewModel.onClickDelete()"/>
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="수정"
          routeName="CONTENT_TEENS_ECONOMIC_NEWS_MODIFY"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="복제"
          routeName="CONTENT_TEENS_ECONOMIC_NEWS_REGISTER"
          :routeQuery="{ copyId : viewModel.model.link }"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import TeensEconomicNewsDetailViewModel from '@/views/content/teensEconomicNews/viewModel/TeensEconomicNewsDetailViewModel'

export default {
  name:'PostDetail',
  components:{
    PageWithLayout,
    TableView,
    Badge,
    Sticky,
    Button,
  },
  beforeMount(){
    const link = this.$route.params.link
    this.viewModel.init(link);
  },
  data(){
    return{
      viewModel: new TeensEconomicNewsDetailViewModel(),
    }
  },
}
</script>
<style scoped>
.img_g{max-width:400px}
</style>